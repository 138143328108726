// React
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import BackgroundImg from 'gatsby-background-image'

const BackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`

// &:after {
//     display: block;
//     content: ' ';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-image: ,
//       linear-gradient(360deg, rgba(0, 0, 0, 5e-5) 0%, rgba(0, 0, 0, 0.5) 100%);
//     transition: background-image 0.25s ease-out;
//   }

const BackgroundImage = styled(BackgroundImg)`
  width: 100%;
  height: 100%;
`
// transition: background-image 0.25s ease-out;

export const Background = ({ image, theme }) => {
  
  // backgroundColor={theme.primaryColor}

  const images = [image.childImageSharp.fluid, `linear-gradient(0deg,rgba(${theme.primaryColorRgb}, 0.3), rgba(${theme.primaryColorRgb}, 0.3))`].reverse()
  return (
    <BackgroundContainer theme={theme}>
      <BackgroundImage
        fluid={images}
        backgroundColor="#000000"
        fadeIn
      />
    </BackgroundContainer>
  )
}

Background.propTypes = {
  image: PropTypes.object,
  theme: PropTypes.object,
}
