// React
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// Organisms
import { Header } from '../organisms/Header'
import { SolutionSelector } from '../organisms/SolutionSelector'

// Styles
import { RoleProp } from '../../styles/SolutionThemes'

// Logo
const logo =
  'https://res.cloudinary.com/optune-me/image/upload/v1479213946/optune/app/logo-optune-neongreen-rgb.png'

// React Component
export const TranslatedSolution = ({ image, role, onSignup }) => {
  const { t } = useTranslation()

  let ref = `solution.${role}`
  const title = t(`${ref}.title`)
  const text = t(`${ref}.text`)
  const buttonText = t(`${ref}.buttonText`)
  const features = t(`${ref}.features`).split('-')
  const solutionLink = t(`${ref}.link`)

  const pricingCHF = t(`${ref}.pricing.chf`)
  const pricingEUR = t(`${ref}.pricing.eur`)

  const loginLink = t('login.link')
  const loginLinkText = t('login.linkText')

  return (
    <Fragment>
      <Header title={t(`solution.${role}.seoTitle`)} />
      <SolutionSelector
        buttonText={buttonText}
        features={features}
        image={image}
        loginLink={loginLink}
        loginLinkText={loginLinkText}
        logo={logo}
        onSignup={onSignup}
        pricingCHF={pricingCHF}
        pricingEUR={pricingEUR}
        role={role}
        solutionLink={solutionLink}
        text={text}
        title={title}
      />
    </Fragment>
  )
}

TranslatedSolution.propTypes = {
  image: PropTypes.object,
  role: RoleProp,
  onSignup: PropTypes.func,
}
