import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

export const Button = ({ onClick, direct, children, ...other }) => {
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        setClicked(() => false)
        onClick()
      }, 250)
    }
  }, [clicked, onClick])

  return (
    <button
      type="button"
      onClick={e => {
        e.preventDefault()
        // Prevent double click
        if (onClick) {
          if (direct) {
            onClick()
          } else if (!clicked) {
            setClicked(() => true)
          }
        }
      }}
      {...other}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  direct: PropTypes.bool,
  onClick: PropTypes.func,
}
