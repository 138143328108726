// React
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

// Styles
import { ColorWhite } from '../../styles/color'
import { Theme, RoleProp } from '../../styles/SolutionThemes'

const TimingDuration = '0.25s'

const Link = styled(AniLink)`
  text-decoration: none;
`

const SolutionCard = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;

  ${props =>
    props.isSelected
      ? css`
          min-width: 76px;
          width: 88px;
          height: 128px;
          margin: 0px 4px;
          box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15),
            0px 2px 4px rgba(0, 0, 0, 0.15);
          opacity: 1;
        `
      : css`
          min-width: 68px;
          width: 80px;
          height: 116px;
          margin: 4px 4px;
          box-shadow: none;
          opacity: 0.75;
        `}


  transition: width ${TimingDuration} ease-out, height ${TimingDuration} ease-out,
    margin ${TimingDuration} ease-out, opacity ${TimingDuration} ease-out;
`

const SolutionCardBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      0deg,
      rgba(${props => props.theme.primaryColorRgb}, 0.3),
      rgba(${props => props.theme.primaryColorRgb}, 0.3)
    ),
    linear-gradient(360deg, rgba(0, 0, 0, 5e-5) 0%, rgba(0, 0, 0, 0.5) 100%),
    url(${props => props.theme.images.card}) no-repeat center center;
  background-size: cover;
`

const SolutionIcon = styled.div`
  position: relative;
  margin-top: ${props => (props.isSelected ? 20 : 22)}px;
  margin-bottom: 10px;
  text-align: center;
  transition: margin ${TimingDuration} ease-out;

  & svg {
    width: ${props => (props.isSelected ? 44 : 40)}px;
    height ${props => (props.isSelected ? 44 : 40)}px;
    transition: width ${TimingDuration}, height ${TimingDuration};
  }
`

const SolutionText = styled.p`
  position: relative;
  margin: 3px;
  text-align: center;
  text-transform: uppercase;
  color: ${ColorWhite};
  line-height: 1.4;
  font-size: 10px;
`

export const SolutionCardNarrow = ({ isSelected, onSelect, role }) => {
  console.log('ROLE', role)
  const theme = Theme[role]
  return (
    <Link fade duration={0.7} to={role}>
      <SolutionCard isSelected={isSelected} theme={theme}>
        <SolutionCardBackground theme={theme} />

        <SolutionIcon isSelected={isSelected}>{theme.icon({})}</SolutionIcon>

        <SolutionText className="font-tiny-regular uppercase">
          {theme.roleText}
        </SolutionText>
      </SolutionCard>
    </Link>
  )
}

SolutionCardNarrow.propTypes = {
  isSelected: PropTypes.bool,
  role: RoleProp,
}
