// React
import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

// Styles
import { MediaSmall, NotMediaSmall } from '../../styles/media'

import { SizeSmall, SizeNano, ZIndex2 } from '../../styles/layout'

const NavigationContainer = styled.nav`
  background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  position: fixed;
  width: 100%;
  z-index: ${ZIndex2};
`

const NavigationGrid = styled.div`
  max-width: 1200px;
  min-height: 50px;

  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: ${SizeSmall};
  padding-right: ${SizeSmall};

  background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  z-index: 50;

  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 200px auto 200px;
  grid-column-gap: 10px;
  align-items: center;

  @media ${MediaSmall} {
    height: auto;
    min-height: 50px;
    grid-template-columns: auto 1fr 100px;
    padding-left: ${SizeNano};
    padding-right: ${SizeNano};
  }
`

const ColumnLeft = styled.div`
  grid-column: 1/1;
  grid-row: 1/1;
`

const ColumnMiddle = styled.div`
  grid-column: 2/2;
  grid-row: 1/1;
  justify-self: center;
  line-height: 0;
`
const ColumnRight = styled.div`
  grid-column: 3/3;
  grid-row: 1/1;
`

const LogoContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 60px;
  display: flex;

  @media ${NotMediaSmall} {
    /* required for IE */
    & img {
      max-height: 30px;
    }
  }

  @media ${MediaSmall} {
    & img {
      width: 40px;
    }
  }
`

export const Navigation = ({ backgroundColor, logo }) => {
  const isDesktop = useMediaQuery({ query: NotMediaSmall })

  const data = useStaticQuery(graphql`
    query {
      logoDesktop: file(relativePath: { eq: "optune-logo.png" }) {
        childImageSharp {
          fixed(height: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoMobile: file(relativePath: { eq: "optune-icon.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <NavigationContainer backgroundColor={backgroundColor}>
      <NavigationGrid backgroundColor={backgroundColor} id="navigation">
        <ColumnLeft>
          <LogoContainer>
            {isDesktop ? (
              <Img
                fixed={data.logoDesktop.childImageSharp.fixed}
                alt="OpTuNE"
              />
            ) : (
              <Img fixed={data.logoMobile.childImageSharp.fixed} alt="OpTuNE" />
            )}
          </LogoContainer>
        </ColumnLeft>

        {isDesktop && (
          <ColumnMiddle>
            <br />
          </ColumnMiddle>
        )}

        <ColumnRight>
          <br />
        </ColumnRight>
      </NavigationGrid>
    </NavigationContainer>
  )
}

Navigation.propTypes = {
  backgroundColor: PropTypes.string,
  isDesktop: PropTypes.bool,
  logo: PropTypes.string,
}
