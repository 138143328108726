import PropTypes from 'prop-types'

import ArtistIcon from '../components/atoms/icons/solutions/Artist'
import AgentIcon from '../components/atoms/icons/solutions/Agent'
import PromoterIcon from '../components/atoms/icons/solutions/Promoter'
import LocationManagerIcon from '../components/atoms/icons/solutions/LocationManager'
import OptuneBrainIcon from '../components/atoms/icons/solutions/OptuneBrain'

const IMAGE_PATH = 'https://res.cloudinary.com/optune-me/image/upload/'
const getImages = ({ url, name }) => ({
  background: name,
  // background: `${IMAGE_PATH}c_scale,fl_progressive,w_1440,q_auto:good${url}`,
  card: `${IMAGE_PATH}c_scale,fl_progressive,h_180,q_auto:best${url}`,
})

export const Theme = {
  artist: {
    icon: ArtistIcon,
    primaryColor: '#00E2B9',
    primaryColorRgb: '0, 226, 185',
    secondaryColor: '#0029E2',
    roleText: 'Artist',
    images: getImages({
      url: '/v1557913263/optune/app/solutions/solution_artist.jpg',
      name: 'solution_artist.jpg', 
    }),
    hook: 'artist',
  },
  manager: {
    icon: AgentIcon,
    primaryColor: '#BD10E0',
    primaryColorRgb: '189, 16, 224',
    secondaryColor: '#ED68C3',
    roleText: 'Booking Agent',
    images: getImages({
      url: '/v1557913263/optune/app/solutions/solution_booking_agent.jpg',
      name: 'solution_booking_agent.jpg', 
    }),
    hook: 'booking-agent',
  },
  promoter: {
    icon: PromoterIcon,
    primaryColor: '#E2B900',
    primaryColorRgb: '226, 185, 0',
    secondaryColor: '#2164D9',
    roleText: 'Promoter',
    images: getImages({
      url: '/v1557913266/optune/app/solutions/solution_promoter.jpg',
      name: 'solution_promoter.jpg', 
    }),
    hook: 'promoter',
  },
  locationManager: {
    icon: LocationManagerIcon,
    primaryColor: '#0029E2',
    primaryColorRgb: '0, 41, 226',
    secondaryColor: '#21D9C3',
    roleText: 'Location Manager',
    images: getImages({
      url: '/v1557913264/optune/app/solutions/solution_location_manager.jpg',
      name: 'solution_location_manager.jpg', 
    }),
    disabled: true,
    hook: 'location-manager',
  },
  optune: {
    icon: OptuneBrainIcon,
    primaryColor: '#27E200',
    primaryColorRgb: '0, 0, 0',
    secondaryColor: '#2164D9',
    roleText: 'Optune',
    images: getImages({
      url: '/v1557913262/optune/app/solutions/solution_optune.jpg',
      name: 'solution_optune.jpg', 
    }),
    hide: true,
    hook: 'optune',
  },
}
export const SolutionRole = {
  ARTIST: 'artist',
  MANAGER: 'manager',
  PROMOTER: 'promoter',
  LOCATIONMANAGER: 'locationManager',
  OPTUNE: 'optune',
}

export const RoleProp = PropTypes.oneOf(Object.values(SolutionRole))
