// React
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Atoms
import { Row } from '../atoms/layout/Row'
import { Column } from '../atoms/layout/Column'
import { ArrowLink } from '../atoms/text/ArrowLink'

// Icons
import CheckIcon from '../atoms/icons/Check'

// Styles
import { ColorHaiti, ColorHarlequin } from '../../styles/color'
import { Theme, RoleProp } from '../../styles/SolutionThemes'

const SolutionInfoCard = styled.section`
  position: relative;
  width: 100%;
`

const InfoRow = styled(Row)`
  margin-bottom: ${({ isDesktop }) => (isDesktop ? 0 : 80)}px;
`
const TextColumn = styled(Column)`
  margin-bottom: 22px;
`

const Title = styled.h1`
  margin-bottom: 11px;
`
const Text = styled.p`
  padding-right: ${({ isDesktop }) => (isDesktop ? 31 : 0)}px;
`
const FeatureList = styled.ul`
  padding-left: ${({ isDesktop }) => (isDesktop ? 31 : 0)}px;

  & > li {
    margin-bottom: 7px;
  }
`
const FeatureIcon = styled(CheckIcon)`
  margin-right: 8px;
  &.icon polyline {
    stroke: ${ColorHarlequin};
    stroke-width: 3px;
  }
`

const MoreText = styled.div`
  margin-top: 0.5rem;
  margin-left: ${({ isDesktop }) => (isDesktop ? 40 : 22)}px;
`

const FeatureItemText = ({ feature }) => (
  <p className="font-micro-regular flex-middle">
    <FeatureIcon className="micro" />
    {feature}
  </p>
)

FeatureItemText.propTypes = {
  feature: PropTypes.string,
}

/*
 * Solution Info
 */
export const SolutionInfo = ({
  isDesktop,
  link,
  role,
  text,
  features,
  title,
}) => (
  <SolutionInfoCard isDesktop={isDesktop}>
    {isDesktop && <Title className="font-big-bold">{title}</Title>}

    <InfoRow isDesktop={isDesktop}>
      <TextColumn>
        <Text className="font-micro-regular">{text}</Text>
      </TextColumn>

      <Column flow="column">
        <FeatureList isDesktop={isDesktop}>
          {features.map((feature, index) => (
            <li key={`feature-${index}`}>
              <FeatureItemText feature={feature} />
            </li>
          ))}
        </FeatureList>

        {link && (
          <MoreText isDesktop={isDesktop}>
            <ArrowLink
              bold
              color={ColorHaiti}
              colorHover={Theme[role].primaryColor}
              isExternal
              link={link}
              text="Learn more"
              className="font-small-bold"
            />
          </MoreText>
        )}
      </Column>
    </InfoRow>
  </SolutionInfoCard>
)

SolutionInfo.propTypes = {
  buttonText: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  isDesktop: PropTypes.bool,
  link: PropTypes.string,
  onSignup: PropTypes.func,
  role: RoleProp,
  text: PropTypes.string,
  title: PropTypes.string,
}

SolutionInfo.defaultProps = {
  buttonText: 'Sign up',
}
