/* eslint-disable max-len */

import React from 'react'
import toSolutionIcon from '../mixins/toSolutionIcon'

const Agent = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M64 32L32 0L0 32L32 64L64 32ZM18.1021 32L32.0002 18.1019L45.8982 32L32.0002 45.8981L18.1021 32Z"
  />
)

export default toSolutionIcon(Agent)('-1 -1 66 66')
