import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const PageContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: 0;
`

export const Page = ({ children }) => <PageContainer>{children}</PageContainer>

Page.propTypes = {
  children: PropTypes.node,
}
