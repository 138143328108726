/* eslint-disable max-len */

// React
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Atoms
import { ArrowLink } from '../atoms/text/ArrowLink'

// Molecules
import { Background } from '../molecules/Background'

// Navigation
import { Navigation } from './Navigation'

// Styles
import { ColorWhite, ColorHarlequin } from '../../styles/color'
import { MaxMediaWidth } from '../../styles/media'
import { Theme, RoleProp } from '../../styles/SolutionThemes'

const Width = `${MaxMediaWidth.small + 1}px`
const Height = '618px'

// const Background = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   background-image: url(${props => props.theme.images.background});
//   background-position: 50% 50%;
//   background-size: cover;
//   transition: background-image 0.25s ease-out;

//   &:after {
//     display: block;
//     content: ' ';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-image: linear-gradient(
//         0deg,
//         rgba(${props => props.theme.primaryColorRgb}, 0.3),
//         rgba(${props => props.theme.primaryColorRgb}, 0.3)
//       ),
//       linear-gradient(360deg, rgba(0, 0, 0, 5e-5) 0%, rgba(0, 0, 0, 0.5) 100%);
//     transition: background-image 0.25s ease-out;
//   }

//   z-index: 0;
// `

const Foreground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 1;
`

const ContentSection = styled.section`
  position: relative;
  width: 100%;
  margin-top: 30px;
`

const OuterCard = styled.div`
  position: relative;
  width: ${Width};
  height: ${Height};
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;

  &:before {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.85;
    background-color: ${ColorWhite};
  }
`

const Content = styled.div`
  position: absolute;
  padding: 34px 53px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
const LoginLink = styled.div`
  margin-top: 15px;
`

export const Step = ({ children, className, image, link, linkText, logo, role }) => (
  <Fragment>
    <Navigation logo={logo} isSignup />

    <Background theme={Theme[role]} image={image} />

    <Foreground>
      <ContentSection>
        <OuterCard className="flex-center">
          <Content>{children}</Content>
        </OuterCard>

        <LoginLink className="flex-center">
          {link && linkText && (
            <ArrowLink
              link={link}
              text={linkText}
              color={ColorWhite}
              colorHover={ColorHarlequin}
              className="font-small-regular"
            />
          )}
        </LoginLink>
      </ContentSection>
    </Foreground>
  </Fragment>
)

Step.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.string,
  linkText: PropTypes.string,
  logo: PropTypes.string,
  role: RoleProp,
}
