/* eslint-disable max-len */

import React from 'react'
import toSolutionIcon from '../mixins/toSolutionIcon'

const Promoter = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M69.282 20L34.641 0L0 20V60L34.641 80L69.282 60V20ZM16 29.2376L34.641 18.4752L53.282 29.2376V50.7624L34.641 61.5248L16 50.7624V29.2376Z"
  />
)

export default toSolutionIcon(Promoter)('-1 -1 70 82')
