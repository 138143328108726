// React
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Atoms
import { ArrowLink } from '../atoms/text/ArrowLink'

// Molecules
import { ActionButton } from '../molecules/ActionButton'
import { SolutionCardNarrow } from '../molecules/SolutionCardNarrow'
import { SolutionInfo } from '../molecules/SolutionInfo'

// Styles
import { ColorStormGray } from '../../styles/color'

import { ZIndex1 } from '../../styles/layout'

import { Theme, RoleProp } from '../../styles/SolutionThemes'

const SolutionListSection = styled.section`
  position: relative;
  width: 100%;
  padding-left: 6px;
  padding-right: 6px;
`

const SolutionSection = styled.section`
  position: relative;
  width: 100%;
  margin-top: 20px;
  padding-left: 18px;
  padding-right: 18px;
`

const SignupSection = styled.section`
  position: fixed;
  left: 18px;
  right: 18px;
  bottom: 45px;
  z-index: ${ZIndex1};
`
const LoginSection = styled.section`
  position: absolute;
  bottom: 15px;
  width: 100%;
  text-align: center;
`

export const SolutionSelectorNarrow = ({
  buttonText,
  features,
  link,
  onSignup,
  pricingCHF,
  pricingEUR,
  role,
  setSelectedRole,
  text,
  title,
}) => (
  <div>
    <SolutionListSection className="flex-center">
      {Object.keys(Theme)
        .filter(roleTheme => !Theme[roleTheme].hide)
        .map(roleTheme => (
          <SolutionCardNarrow
            key={roleTheme}
            isSelected={roleTheme === role}
            role={roleTheme}
          />
        ))}
    </SolutionListSection>

    <SolutionSection>
      <SolutionInfo
        link={link}
        role={role}
        text={text}
        title={title}
        features={features}
      />
    </SolutionSection>

    <LoginSection>
      <ArrowLink
        link="/#login"
        text="Already have an account? Login here"
        color={ColorStormGray}
        className="font-tiny-regular"
      />
    </LoginSection>

    <SignupSection className="flex-center">
      <ActionButton
        disabled={Theme[role].disabled}
        onClick={onSignup}
        data-cy={`signup-as-${Theme[role].hook}-button`}
      >
        {buttonText}
      </ActionButton>
    </SignupSection>
  </div>
)

SolutionSelectorNarrow.propTypes = {
  buttonText: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.string,
  onSignup: PropTypes.func,
  pricingCHF: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pricingEUR: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  role: RoleProp,
  setSelectedRole: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
}
