import React from 'react'
import toSquidInkIcon from './mixins/toSquidInkIcon'

const Check = () => (
  <g id="check">
    <polyline
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      points="8,26 18,36 40,12"
    />
  </g>
)

export default toSquidInkIcon(Check)
