// React
import React from 'react'
import PropTypes from 'prop-types'

// Atoms
import { Page } from '../atoms/layout/Page'

// Templates
import { TranslatedSolution } from './TranslatedSolution'

// Styles
import { RoleProp } from '../../styles/SolutionThemes'

// React Component
export const Solution = ({ navigate, image, role }) => {
  return (
    <Page>
      <TranslatedSolution
        image={image}
        onSignup={() => navigate(`https://app.optune.me/${role}/signup#signup`)}
        role={role}
      />
    </Page>
  )
}

Solution.propTypes = {
  image: PropTypes.object,
  navigate: PropTypes.func,
  role: RoleProp,
}
