// React
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Atoms
import { Button } from '../atoms/buttons/Button'

// Styles
import {
  ColorDustyGray,
  ColorHaiti,
  ColorHarlequin,
  ColorLightGrey,
  ColorWhite,
} from '../../styles/color'

import { MediaSmall } from '../../styles/media'

export const StyledButton = styled(Button)`
  position: relative;
  cursor: pointer;
  text-align: center;
  height: 43px;
  color: ${props => (props.disabled && ColorDustyGray) || ColorWhite};
  background-color: ${props =>
    (props.disabled && ColorLightGrey) || ColorHarlequin};
  box-shadow: ${props =>
    props.disabled ? 'none' : '6px 6px 0px rgba(39, 226, 0, 0.2)'};
  padding: 4px 15px;
  transition: all 0.3s;
  width: 231px;
  border: none;

  @media ${MediaSmall} {
    width: 100%;
  }

  &:hover {
    color: ${ColorHarlequin};
    background-color: ${ColorHaiti};
  }
`

export const ActionButton = ({ children, disabled, ...other }) => (
  <StyledButton
    className="font-small-regular uppercase"
    disabled={disabled}
    {...other}
  >
    {children}
  </StyledButton>
)

ActionButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
}
