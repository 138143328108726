/* eslint-disable max-len */

import React from 'react'
import toSolutionIcon from '../mixins/toSolutionIcon'

const Artist = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M40 0L0 68H80L40 0ZM40 31.5569L27.9747 52H52.0253L40 31.5569Z"
  />
)

export default toSolutionIcon(Artist)('-1 -1 82 66')
