/* eslint-disable max-len */

import React from 'react'
import toSolutionIcon from '../mixins/toSolutionIcon'

const LocationManager = () => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M68.2843 11.7157L40 0L11.7157 11.7157L0 40L11.7157 68.2843L40 80L68.2843 68.2843L80 40L68.2843 11.7157ZM17.3183 40L23.9616 23.9616L40 17.3183L56.0384 23.9616L62.6817 40L56.0384 56.0384L40 62.6817L23.9616 56.0384L17.3183 40Z"
  />
)

export default toSolutionIcon(LocationManager)('-1 -1 82 82')
