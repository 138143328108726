export const SizeNano = '5px'
export const SizeMicro = '10px'
export const SizeMini = '15px'
export const SizeSmall = '20px'
export const SizeMedium = '30px'
export const SizeLarge = '40px'

export const ZIndex1 = 999
export const ZIndex2 = 9999
export const ZIndex3 = 99999
export const ZIndex4 = 999999
