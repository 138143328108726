// React
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Styles
import { FontSizeSmall } from '../../../styles/font'

const Link = styled.a`
  display: flex;
  justify-content: center;
  height: ${FontSizeSmall};
  color: ${props => props.color};
  transition: color 0.3s;

  & svg {
    padding-top: 2px;
    width: ${FontSizeSmall};
    height: ${FontSizeSmall};

    & polyline {
      stroke: ${props => props.color};
      transition: stroke 0.3s;
    }
  }

  &:hover {
    color: ${props => props.colorHover};

    & svg {
      & polyline {
        stroke: ${props => props.colorHover};
      }
    }
  }
`

const LinkText = styled.p`
  margin-right: 0.5rem;
`

const Icon = ({ bold }) => (
  <svg
    enableBackground="new 0 0 48 48"
    version="1.1"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline
      fill="none"
      strokeWidth={bold ? '7px' : '5px'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      points="13,1.9999924 35,23.9999924 13,45.9999924"
    />
  </svg>
)

Icon.propTypes = {
  bold: PropTypes.bool,
}

export const ArrowLink = ({
  bold,
  className,
  color,
  colorHover,
  isExternal,
  link,
  text,
}) =>
  isExternal ? (
    <Link
      color={color}
      colorHover={colorHover}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <LinkText className={className}>{text}</LinkText>
      <Icon bold={bold} />
    </Link>
  ) : (
    <Link color={color} colorHover={colorHover} href={link}>
      <LinkText className={className}>{text}</LinkText>
      <Icon bold={bold} />
    </Link>
  )

ArrowLink.propTypes = {
  bold: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  isExternal: PropTypes.bool,
  link: PropTypes.string,
  text: PropTypes.string,
}
