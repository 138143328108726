import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { SolutionSelectorNarrow } from './SolutionSelectorNarrow'
import { SolutionSelectorWide } from './SolutionSelectorWide'

import { NotMediaSmall } from '../../styles/media'
import { RoleProp, SolutionRole } from '../../styles/SolutionThemes'


export const SolutionSelector = ({ role, ...other }) => {
  const isDesktop = useMediaQuery({ query: NotMediaSmall })

  return isDesktop ? (
    <SolutionSelectorWide role={role} {...other} />
  ) : (
    <SolutionSelectorNarrow role={role} {...other} />
  )
}

SolutionSelector.propTypes = {
  role: RoleProp,
}

SolutionSelector.defaultProps = {
  role: SolutionRole.OPTUNE,
}
