// React
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// Molecules
import { ActionButton } from '../molecules/ActionButton'
import { SolutionCardWide } from '../molecules/SolutionCardWide'
import { SolutionInfo } from '../molecules/SolutionInfo'

// Organisms
import { Step } from './Step'

// Styles
import { FontSizeXLarge } from '../../styles/font'

import { ZIndex1 } from '../../styles/layout'

import { Theme, RoleProp } from '../../styles/SolutionThemes'

const Title = styled.h1`
  margin-top: 20px;
  margin-left: 40px;
  font-size: ${FontSizeXLarge};
`

const SolutionListSection = styled.section`
  margin-top: 56px;
  display: flex;
`

const SolutionSection = styled.section`
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
`

const SignupSection = styled.section`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 34px;
  z-index: ${ZIndex1};
`

export const SolutionSelectorWide = ({
  buttonText,
  features,
  image,
  loginLink,
  loginLinkText,
  logo,
  onSignup,
  pricingCHF,
  pricingEUR,
  role,
  solutionLink,
  text,
  title,
}) => (
  <Step
    image={image}
    link={loginLink}
    linkText={loginLinkText}
    logo={logo}
    role={role}
  >
    <Title className="font-large-bold">A solution for everyone</Title>
    <SolutionListSection>
      {Object.keys(Theme)
        .filter(roleTheme => !Theme[roleTheme].hide)
        .map(roleTheme => (
          <SolutionCardWide
            key={roleTheme}
            isSelected={roleTheme === role}
            role={roleTheme}
          />
        ))}
    </SolutionListSection>

    <SolutionSection>
      <SolutionInfo
        features={features}
        isDesktop
        link={solutionLink}
        role={role}
        text={text}
        title={title}
      />
    </SolutionSection>

    <SignupSection className="flex-center">
      <ActionButton disabled={Theme[role].disabled} onClick={onSignup}>
        {buttonText}
      </ActionButton>
    </SignupSection>
  </Step>
)

SolutionSelectorWide.propTypes = {
  buttonText: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.object,
  loginLink: PropTypes.string,
  loginLinkText: PropTypes.string,
  logo: PropTypes.string,
  onSignup: PropTypes.func,
  pricingCHF: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pricingEUR: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  role: RoleProp,
  solutionLink: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
}
