import classNames from 'classnames'

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ColumnContainer = styled.div`
  display: ${({ center, flow, spaceBetween }) =>
    center || spaceBetween || flow > '' ? 'flex' : 'block'};
  justify-content: ${({ center, spaceBetween }) =>
    (center && 'center') || (spaceBetween && 'space-between') || 'flex-start'};
  align-items: center;
  flex-flow: ${({ flow }) => flow || 'column'};
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  max-height: ${({ hide }) => (hide ? 0 : 'auto')};
  transition: opacity 0.3s, max-height 0.3s;
`

export const Column = ({ children, className, ...other }) => (
  <ColumnContainer className={classNames('column half', className)} {...other}>
    {children}
  </ColumnContainer>
)

Column.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  flow: PropTypes.string,
  hide: PropTypes.bool,
  spaceBetween: PropTypes.bool,
}
